<template>
  <v-row>
    <v-col cols="12" md="3">
      <AdminMenu></AdminMenu>
    </v-col>
    <v-col cols="12" md="9" class="adminBg">
      <v-layout wrap class="mt-3">
        <v-col cols="12">
          <h4>Professional Module</h4>
        </v-col>
      </v-layout>

      <v-layout wrap>
        <v-col cols="12">
          <v-layout wrap class="whiteBg" v-if="userAccounts.length >= 1">
            <v-col cols="12">
              <v-layout wrap>
                <h5>Professional</h5>
              </v-layout>

              <v-layout wrap>
                <v-col cols="12">
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Email
                          </th>
                          <th class="text-left">
                            Profession
                          </th>
                          <th class="text-left">
                            Status
                          </th>
                          <th class="text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="user in userAccounts" :key="user.userId">

                          <td style="font-size:12px;">
                              {{user.name}}
                          </td>

                          <td style="font-size:12px;">
                              {{user.email}}
                          </td>

                          <td style="font-size:12px;">
                              {{user.Professional.profession}}
                          </td>

                          <td style="font-size:12px;">
                              <span v-if="user.isActive"  style="color:green;"> Approved</span> <span style="color:red;" v-else> Not Approved</span> 
                          </td>
                        
                         
                          <td style="font-size:12px;">
                                <v-btn rounded small color="#F12B2C" dark @click="openViewDialog(user)"
                                  >View</v-btn
                                >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>

            <v-dialog v-model="viewDialog" width="550">
                <v-card>
                  <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4" align="center">
                            <v-avatar size="100px">
                                <img
                                    :src="user.Professional.profileImageUrl"
                                    alt="User Image"
                                >
                            </v-avatar>
                        </v-col>

                        <v-col cols="12" md="8">
                            <p> 
                                <v-text-field label="Full Name" dense :value="user.name" readonly>
                                </v-text-field>
                            </p>
                            <p>
                                <v-text-field label="Email" dense :value="user.email" readonly>
                                </v-text-field> 
                            </p>
                            <p>
                                <v-text-field label="Phone" dense :value="user.phoneNumber" readonly>
                                </v-text-field>
                            </p>
                            <p>
                                <v-textarea label="Your Bio" readonly value="Hello, I'm Irozuru Chime Emmanuel, web-developer based in Umuahia, Abia State, Nigeria. I have rich experience in full-stack"></v-textarea>
                            </p>

                            <h5>Licensing Information</h5>

                            <p class="mt-5">
                                <v-text-field label="Licencing Number" dense :value="user.Professional.licenseNumber" readonly>
                                </v-text-field>
                            </p>

                            <p class="mt-1" align="center">
                                <label>License Image</label>
                                <v-img
                                    lazy-src="https://picsum.photos/id/11/10/6"
                                    max-height="150"
                                    max-width="250"
                                    src="https://picsum.photos/id/11/500/300"
                                    ></v-img>
                            </p>

                            <p class="mt-3">
                                <v-progress-linear
                                    class="mt-3"
                                    color="#7CBF46"
                                    v-show="loader"
                                    :indeterminate="true"
                                ></v-progress-linear>
                            </p>

                            <p class="mt-1" align="center">
                                <v-btn small color="green" dark v-if="!user.isActive" @click="activateAccount(user.userId)">Approve Account</v-btn>
                                <v-btn small color="red" dark v-else @click="deactivateAccount(user.userId)">Block Account</v-btn>
                            </p>
                    </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
            </v-dialog>

    </v-col>
  </v-row>
</template>
<script>
import AdminMenu from "../others/AdminMenu";
export default {
  name: "AdminTransactionsModule",
  components: {
    AdminMenu,
  },
  data() {
    return {
      viewDialog:false,
      loader:false,
      userAccounts: [],
      roles: [],
      user:{
          Professional:{
              profileImageUrl:null,
              licenseNumber:""
          }
      },
      systemSetting: {
        systemCurrency: "₦",
      },
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers(){
        this.$http
        .get(`${process.env.VUE_APP_URL}users`)
        .then((response) => {
          response.data.data.forEach(element => {
              if(element.Role.name == 'professional')
              {
                  this.userAccounts.push(element);
              }
          });
        })
        .catch((error)=> {
          console.log(error.response);
        })
    },
    openViewDialog(user) {
        this.user = user;
        this.viewDialog = true;
    },
    activateAccount(userId){
        this.loader =true;
        this.user = {
          Professional:{
              profileImageUrl:null,
              licenseNumber:""
          }
        };

        const data = {
            isActive: true
        };

        this.$http
        .put(`${process.env.VUE_APP_URL}user/activateaccount/` + userId, data)
        .then((response) => {
          this.loader =false;
          this.user = response.data.data;
        });
    },

    deactivateAccount(userId){
        this.loader =true;
        this.user = {
          Professional:{
              profileImageUrl:null,
              licenseNumber:""
          }
        };

        const data = {
            isActive: false
        };

        this.$http
        .put(`${process.env.VUE_APP_URL}user/activateaccount/` + userId, data)
        .then((response) => {
          this.loader =false;
          this.user = response.data.data;
        });
    }



  },
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.whiteBg {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.boxStat {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}
</style>
